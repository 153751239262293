import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

export default function Welcome() {
    const history = useHistory()

    return (
        <>
            <Card>
                <Card.Body>
                    <h2 className="text-center mb-4">STEMadium Code Redemption</h2>
                    <label className="text-center mb-2">Welcome! You can redeem your STEMadium code on this site. Have you started playing STEMadium yet?</label>
                    <Button className="w-100 mt-3" class="btn btn-primary" onClick={() => {
                        history.push('/get-started')
                    }}>
                        Yes, I Have Started Playing
                    </Button>
                    <Button className="w-100 mt-3" class="btn btn-secondary" onClick={() => {
                        history.push('/signup')
                    }}>
                        No, I Haven't Played Yet
                    </Button>
                </Card.Body>
            </Card>
        </>
    )
}
