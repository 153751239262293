import React from 'react'
import { Container } from 'react-bootstrap';
import AuthProvider from '../contexts/AuthContext';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Dashboard from './Dashboard';
import PrivateRoute from './PrivateRoute';
import Signup from './Signup';
import Welcome from './Welcome';
import GetStarted from './GetStarted';
import ConvertAccount from './ConvertAccount';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import UpdateProfile from './UpdateProfile';

import BackgroundImage from '../images/SOSBackground_Blue.jpg'

function App() {
  return (
    <div style={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: '1750px' }}>
      <Container className="d-flex align-items-center justify-content-center"
            style={{ minHeight:"100vh" }}
          >
            <div className="w-100" style={{ maxWidth: "400px" }}>
              <Router>
                <AuthProvider>
                  <Switch>
                    <PrivateRoute exact path="/" component={Dashboard} />
                    <PrivateRoute path="/update-profile" component={UpdateProfile} />
                    <Route path="/welcome" component={Welcome} />
                    <Route path="/get-started" component={GetStarted} />
                    <Route path="/convert-account" component={ConvertAccount} />
                    <Route path="/signup" component={Signup} />
                    <Route path="/login" component={Login} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                  </Switch>
                </AuthProvider>
              </Router>
            </div>
          </Container>
    </div>
  )
}

export default App;
