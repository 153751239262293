import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { useHistory, Link } from 'react-router-dom'

export default function GetStarted() {
    const history = useHistory()

    return (
        <>
            <Card>
                <Card.Body>
                    <label class="text-center">Great! We hope you're having fun so far! Have you created an account with an email and password?</label>
                    <Button className="w-100 mt-3" class="btn btn-primary" onClick={() => {
                        history.push('/login')
                    }}>
                        Yes, I Created an Account
                    </Button>
                    <Button className="w-100 mt-3" class="btn btn-secondary" onClick={() => {
                        history.push('/convert-account')
                    }}>
                        No, I am Playing as a Guest
                    </Button>
                    <div className="w-100 text-center mt-3">
                        <Link to="/welcome">Back</Link>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}
