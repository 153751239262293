import React, { useContext, useState, useEffect } from 'react'
import { auth, db } from '../firebase'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export default function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    // Signs up a user, returning a promise with user credentials or an error.
    // To use this in other scripts, it must be exported in the const value below.
    function signup(email, password) {
        return auth.createUserWithEmailAndPassword(email, password)
    }

    // Logs in a user, returning a promise with user credentials or an error.
    // To use this in other scripts, it must be exported in the const value below.
    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    // Sends a password reset email, returning a promise with an error if one exists.
    // To use this in other scripts, it must be exported in the const value below.
    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    // Logs out a user, returning a promise with an error if something went wrong.
    // To use this in other scripts, it must be exported in the const value below.
    function logout() {
        return auth.signOut()
    }

    // function updateEmail(email) {
    //     return currentUser.updateEmail(email)
    // }

    // function updatePassword(password) {
    //     return currentUser.updatePassword(password)
    // }

    function getCodeStatus(code) {
        return db.collection('unlockCodes').document(code).get()
    }

    function setCodeUsed(code) {
        return db.collection('unlockCodes').document(code).set({ redeemed: true, redeemedUserID: currentUser.id })
    }

    useEffect(() => {
        const unsubscibe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })

        return unsubscibe
    }, [])
    
    // This is how we export our functions (such as the above login and signup) to be usable in other scripts.
    const value = {
        currentUser,
        login,
        signup,
        resetPassword,
        logout,
        getCodeStatus,
        setCodeUsed
    }
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
