import React, { useState, useRef, useEffect } from 'react'
import { Card, Button, Alert, Form } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import { auth, db } from '../firebase'
import StoreBadge from 'react-store-badge'

export default function Dashboard() {
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [isUnlocked, setUnlocked] = useState(false)
    const { logout } = useAuth()
    const history = useHistory()
    const codeRef = useRef()

    useEffect(() => {
        setLoading(true)
        setMessage("")
        setError("")
        db.collection('userData').doc(auth.currentUser.uid).get().then((doc) => {
            if(doc.exists) {
                if(doc.data().unlockedFullGame) {
                    console.log("Unlocked: " + doc.data().unlockedFullGame)
                    setMessage('You\'ve already redeemed a code! Download STEMadium and log in to Play!')
                    setUnlocked(true)
                }
                else {
                    setUnlocked(false)
                }
            }
            else {
                setUnlocked(false)
            }
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setError("There was a problem checking your account.")
            setLoading(false)
        }) 
    }, [])

    async function handleLogout() {
        setError('')

        try {
            await logout()
            history.push('/login')
        } catch {
            setError('Failed to log out')
        }
    }

    function handleSubmit(e) {
        e.preventDefault()

        setError("")
        setLoading(true)

        db.collection('unlockCodes').doc(codeRef.current.value).get().then(doc => {
            if(doc.exists) {
                // The code is used. Check if the current user is the owner.
                if(doc.data().redeemed === true) {
                    if(doc.data().redeemedUserID === auth.currentUser.uid) {
                        setMessage('You have already redeemed this code!')
                    }
                    else {
                        setError('This code has already been used by another user.')
                    }
                    setLoading(false)
                }
                // This code is not used, go ahead and redeem it.
                else {
                    handleRedeem()
                }
            }
            // The document does not exist, this is an invalid code.
            else {
                setError('Invalid code.')
                setLoading(false)
            }
        }).catch(() => {
            setError('There was a problem checking the code.')
            setLoading(false)
        })
    }

    function handleRedeem() {
        setError("")
        setLoading(true)

        db.collection('userData').doc(auth.currentUser.uid).set({
            unlockedFullGame: true
        }, { merge: true }).then(() => {
            // User's profile was successfully updated. Now we make the code as consumed.
            setCodeUsed()
        }).catch((error) => {
            setError('There was a problem redeeming that code 1.' + error)
            setLoading(false)
        })
    }

    function setCodeUsed() {
        setError("")
        setLoading(true)

        db.collection('unlockCodes').doc(codeRef.current.value).set({
            redeemed: true,
            redeemedUserID: auth.currentUser.uid
        }, { merge: true }).then(() => {
            // Code successfully updated! We're done.
            setMessage('Code redeemed! Close the app and re-open it to gain access to the new lessons! Download the app here if you need to:')
            setUnlocked(true)
        }).catch((error) => {
            setError('There was a problem redeeming that code 2.')
        }).finally(() => {
            setLoading(false)
        })
        
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <h2 className="text-center mb-4">Redeem Code Here</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {message && <Alert variant="success">{message}</Alert>}
                    {isUnlocked && (
                        <div>
                            <StoreBadge
                                name="STEMadium"
                                googlePlayUrl="https://play.google.com/store/apps/details?id=com.dfusion.stemadium.pii.ii"
                                appStoreUrl="https://apps.apple.com/us/app/stemadium/id1579244448"
                            />
                            <div className="w-100 text-center mt-2">
                                <Button variant="link" onClick={handleLogout}>Log Out</Button>
                            </div>
                        </div>
                    ) }
                    {!isUnlocked && (
                        <div>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id="code">
                                    <Form.Label>Enter Code</Form.Label>
                                    <Form.Control ref={codeRef} required />
                                </Form.Group>
                                <Button className="w-100 mt-3" type="submit" disabled={loading}>
                                    Redeem Code
                                </Button>
                            </Form>
                            <div className="w-100 text-center mt-2">
                                <Button variant="link" onClick={handleLogout}>Log Out</Button>
                            </div>
                        </div>
                    )}
                    
                </Card.Body>
            </Card>
        </>
    )
}