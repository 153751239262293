import React from 'react'
import { Card, Button, Carousel } from 'react-bootstrap'
import { useHistory, Link } from 'react-router-dom'

import TutorialImage1 from '../images/STEMadium_WebInstructions_1.png'
import TutorialImage2 from '../images/STEMadium_WebInstructions_2.png'
import TutorialImage3 from '../images/STEMadium_WebInstructions_3.png'

export default function ConvertAccount() {
    const history = useHistory()

    return (
        <>
            <Card>
                <Card.Body>
                    <label className="text-center mb-2">To redeem your code, you'll need a STEMadium account. Please open the STEMadium app, return to the Clubhouse, and tap on the settings icon in the top-right corner of the screen, and tap Create Account.</label>
                    <Carousel fade indicators nextLabel={null} prevLabel={null} variant="dark" >
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={TutorialImage1}
                                alt="Open settings"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={TutorialImage2}
                                alt="Start creating account"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={TutorialImage3}
                                alt="Open settings"
                            />
                        </Carousel.Item>
                    </Carousel>
                    <label className="text-center mt-2">Tap Continue once you've created your account in the app!</label>
                    <Button className="w-100 mt-3" class="btn btn-primary" onClick={() => {
                        history.push('/login')
                    }}>
                        Continue
                    </Button>
                    <div className="w-100 text-center mt-3">
                        <Link to="/get-started">Back</Link>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}
