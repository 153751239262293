import firebase from 'firebase/app'
import "firebase/auth"
import "firebase/firestore"

const app = firebase.initializeApp({
    apiKey: "AIzaSyDa4_CEjbxmNEaPyQv13ybTxxNY7aSWARg",
    authDomain: "stemadium.firebaseapp.com",
    databaseURL: "https://stemadium.firebaseio.com",
    projectId: "stemadium",
    storageBucket: "stemadium.appspot.com",
    messagingSenderId: "332420161459",
    appId: "1:332420161459:web:aea350937497dbdd5b75c6",
})

export const auth = app.auth()
export const db = app.firestore()
export default app